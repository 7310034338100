.App {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
html, body, #root {
  height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*#form { background: rgba(0, 0, 0, 0.15); padding: 0.25rem;  bottom: 0; left: 0; right: 0; display: flex; height: 3rem; box-sizing: border-box; backdrop-filter: blur(10px); }*/
/*#input { border: none; padding: 0 1rem; flex-grow: 1; border-radius: 2rem; margin: 0.25rem; }*/
#input:focus { outline: none; }

#messages { list-style-type: none; margin: 0; padding: 0; }
#messages > li { padding: 0.5rem 1rem; }
#messages > li:nth-child(odd) { background: #efefef; }


@font-face {
  font-family: "Branding Semibold";
  src: local("Branding Semibold"),
  url("./resources/fonts/branding/Branding Semibold.otf") format("truetype");
}

@font-face {
  font-family: "Branding Bold";
  src: local("Branding Bold"), url("./resources/fonts/branding/Branding Bold.otf") format("truetype");
}

@font-face {
  font-family: "Branding Medium";
  src: local("Branding Medium"), url("./resources/fonts/branding/Branding Medium.otf") format("truetype");
}
